.btn , .firstBtn , .logoutbtn{
  text-decoration: none;
  width: 350px;
  margin-left: 27px;
  border-radius: 8px;
  padding: 16px 20px 16px 20px;
  background: transparent;
  color:var(--white);
  font-size: 24px;
  line-height: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
}

.firstBtn {
  background: var(--yellowColor);
  color: #1e3a4f;
  font-weight: 700;
}

.firstBtn:after {
  content: '\2192';
  display: inline-block !important;
}

.btn:hover , .logoutbtn:hover{
  background: var(--yellowColor);
  color: #1e3a4f;
}

.logoutbtn {
  background: transparent;
  color: var(--yellowColor);
}

.homeButton {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 12px;
}
.listContainer{
  display: block !important;
  color: var(--white) !important; 
}
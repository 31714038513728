@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

:root {
  --primaryColor: #1976d2;
  --successColor: #2e7d32;
  --errorColor: #d32f2f;
  --backgroundColor: #2d4254;
  --normalTextColor: #1e3a4f;
  --grayMedium: #375062;
  --yellowColor: #efc415;
  --textColorDark: #718390;
  --white: #ffffff;
  --redColor: #CB1E32;
  --lightBlue: #e2edf8;
  --lightGray: #1e3a4f0d;
  --lightGreen: #88d08b33;
  --darkYellow: #d29a55;
  --lightRed: #ffbdc1;
  --skyBlue: #229dc7;
  --blueColor: #233BB6;
  --darkBlue: #072b50;
  --gray: #e3e7e9;
  --faintGreen: #e0f5e8;
  --timelineColor: #7697bf;
  --pitchColor: #f5f5f5;
  --lightOrange: #f5eae0;
  --amberColor: #efac44;
  --blackColor: #000000;
  --grayDark: #9ba3a7;
  --fieldsColorGray: #878787;
  --lightgreenColor: #36A942;
  --lightGrayColor: #B8B8B8;
}
.homeContainer {
  display: block;
  float: right;
  margin-top: 100px;
  width: 33%;
}
.firstContent{
  font-size: 50px !important;
  color: var(--white) !important;
}
.secondContent{
  font-size: 30px !important;
  color: var(--white) !important;
}

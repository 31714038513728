.grid {
    border-radius: 24px;
    background: var(--white);
    margin: 0 auto;
    padding-bottom: 10px;
}

.dataGridStyle {
    line-Height: 24px;
}
.trusted {
    color: var(--normalTextColor);
}

.notSystem {
    color: var(--primaryColor);
}

.first {
    padding-Left: 35px;
}

.closeIcon {
    cursor: pointer;
    position: absolute;
    align-items: center;
    display: flex;
    right: 24px;
    top: 20px;
}

.secondaryHeaderSubTitle {
    min-width: 60% !important;
    margin-left: 20px !important;
    flex-grow: 1 !important;
}

.createButton {
    border-radius: 4px;
    width: 129px;
    height: 37px;
    float: right;
    margin-top: 30px;
    background: var(--yellowColor);
    color: var(--blackColor);
    padding: 10px 20px 10px 20px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    line-height: 17px;
}

.fieldDisabled {
    opacity: 0.5;
}

.alertContainer {
    margin: 30px 50px 30px 50px;
}

.pageContainer {
    padding: 50px;
    width: 100%;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
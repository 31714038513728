.container {
    width: 308px;
    background: var(--pitchColor);
    margin: 0 0 20px 40px;
    min-height: 350px;
    border-radius: 4px;
}

.headerContainer {
    width: 100%;
    top: 10px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    position: relative;
    height: 24px;
}

.headerTrailerIdContainer {
    display: flex;
    width: 90%;
}

.headerTrailerId {
    max-width: 30%;
    margin-left: 16px !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
}

.headerStatus {
    display: flex;
    margin-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
}

.trailerIconContainer {
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
}
.activityIcon{
    position: relative;
    margin-right: 225px;
}

.iconTruckWithBars {
    position: absolute;
}

.divider {
    width: 288px;
    border: 1px solid;
    margin: 50px 0 10px 10px;
    color: var(--lightGrayColor);
}

.trailerDetailsWrapper {
    padding: 15px;
    width: 100%;
}

.trailerDetailContainer {
    display: flex;
    height: 30px;
}

.locationContainer,
.noLocationText,
.battery {
    min-width: 54%;
    display: flex;
    align-items: center;
    gap: 4px;
}

.noLocationText {
    color: var(--fieldsColorGray);
}

.totalMass {
    min-width: 46%;
    display: flex;
}

.fullDetails {
    width: 50% !important;
    align-items: center;
    text-align: left !important;
}

.lastUpdatedContainer,
.lastUpdatedContainerForNoData {
    display: flex;
    margin-top: 7px;
    height: 26px;
    width: 100%;
    border-radius: 0 0 4px 4px;
    align-items: center;
}

.lastUpdatedContainer {
    background: var(--gray);
}

.lastUpdatedContainerForNoData {
    background: var(--redColor);
    color: var(--white);
}

.chipActive {
    margin-left: 16px;
    background-color: var(--lightgreenColor) !important;
}

.chipInActive {
    margin-left: 16px;
    background-color: var(--grayDark) !important;
}

.chipWithNoData {
    background-color: var(--white) !important;
}
.loadingContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  width: 480px;
  height: 640px;
  background: var(--white);
  border-radius: 16px;
  padding: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.loading p {
  font-size: 32px;
  line-height: 40px;
  color: var(--normalTextColor);
}
.loader {
  border: 10px solid #eaeaeb;
  border-radius: 50%;
  border-top: 10px solid var(--yellowColor);
  width: 64px;
  height: 64px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
